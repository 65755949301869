import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Seo from "../layout/Seo";
const Base = ({ children }) => {
    return (
        <React.Fragment>
            <Seo />
            <Header/>
            <div className="container is-fluid is-paddingless">
            <div className="content">{children}</div>
            <Footer />
            </div>
        </React.Fragment>
    );
};

export default Base;
