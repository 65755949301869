
import Banner1 from "../assets/banner2.jpg";

const descript = [

  {
    name: 'Kit de Reciclaje',
    info: `¡Separando residuos para dummies!
    Solo necesitas plástico, papel, cartón, vidrios, metales y seguir las instrucciones para cada uno de los colores que aparecen impresos en cada bolsa. ¡Es así de fácil como suena! Además son muy cómodas de llevar al punto limpio.`,
    price: "9.990",
    link: "https://denda.cl/products/kit-de-reciclaje-en-bolsa",
    image: Banner1
  }
]

export default descript