import React from "react"
import Base from "../layout/Base"

const Comocomprar = () => {
    return (
        <Base>
              <div id='collection-component-shopify' style={{display: "none"}}></div>
            <div className="text-page-title"><p><b>¿Como comprar en Plant B?</b></p></div>
            <div class="text-page-content">
            <p><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}>&iquest;Est&aacute;s interesado en hacernos una compra? &iexcl;Felicitaciones, decidiste empezar a cambiar el mundo!&nbsp;Comprar en Plant B es muy f&aacute;cil. Realizamos ventas al por mayor a todo Chile, tan solo tienes que escribirnos a&nbsp;</span><a href="mailto:contacto@plantb.cl"><span style={{fontWeight: "400;"}}>contacto@plantb.cl</span></a><span style={{fontWeight: "400;"}}>&nbsp;y nos pondremos en contacto contigo lo antes posible.</span></p>
            </div>
        </Base>
    )
}
export default Comocomprar;
