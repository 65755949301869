import React from "react"
import Base from "../layout/Base"

const Quees = () => {
    return (
        <Base>
              <div id='collection-component-shopify' style={{display: "none"}}></div>
            <div className="text-page-title"><p><b>¿Que es Plant B?</b></p></div>
            <div class="text-page-content">
<p>
    <span style={{"fontWeight": "400"}}>-Plant&nbsp;B&nbsp;nace como respuesta para todos aquellos que est&aacute;n buscando c&oacute;mo empezar y acercarse a una vida m&aacute;s eco amigable, responsable y sustentable con el planeta, ofreciendo productos para reciclaje, jardiner&iacute;a, compostaje, etc. Nuestra misi&oacute;n es minimizar entre todos nuestra huella de carbono, modificando nuestros h&aacute;bitos y haci&eacute;ndonos&nbsp;conscientes de que todas las decisiones que tomamos pueden ser menos contaminantes. No necesitas ser un experto en compostaje o en reciclaje, nosotros te ayudamos de una manera f&aacute;cil, econ&oacute;mica y r&aacute;pida.&nbsp;</span></p>

            </div>
        </Base>
    )
}
export default Quees;
