import React from "react";
import MercadoPago from "../assets/mercado-pago.svg";
// import CyberMonday from "../assets/sello-cyber-monday.png";
import {ReactComponent as LogoWhite} from "../assets/svg/logoplantbblanco.svg"
import {ReactComponent as InstaIcon} from '../assets/svg/instagram.svg'
import {ReactComponent as FaceIcon} from '../assets/svg/facebook.svg'
import {ReactComponent as EmailIcon} from '../assets/svg/envelopeIcon.svg'

import history from "../helpers/history"

const Footer = () => {
    const handelTerminos = (e) =>{
        history.push("/Terminos")
    }
    const handleContacto = (e) =>{
        history.push("/Contacto")
    }
    const handleDespacho = (e) =>{
        history.push("/Despacho")
    }
    const handleQuees = (e) =>{
        history.push("/Quees")
    }
    const handleComocomprar = (e) =>{
        history.push("/Comocomprar")
    }
    const handlePoliticas = (e) =>{
        history.push("/Politicas")
    }
    const handlePreguntas = (e) =>{
        history.push("/Preguntas")
    }
    return (
        <footer>
                    <div className="link-venta-mayor">
                        <div onClick={handleContacto}>
                            <span>Compra por Mayor</span>
                            <EmailIcon className="contacto-mayor"/>
                        </div>
                    </div>
                    <div className="divider footer-green columns desktop-description">
                        <div className="contacto column is-2 footer-column-divisor">
                            {/* <img src={BtnContacto} alt="" />
                            <div className="contact-input" role="button" tabIndex="0" onClick={handleModal} onKeyPress={handleModal} >
                                <span>Compra por mayor</span>
                                <div className="contact-input-icon">
                                    <EmailIcon />
                                </div>
                            </div> */}
                            <ul className="footer-list">
                                <li>
                                    <span onClick={handleQuees}>¿Qué es Plant B?</span>
                                </li>
                                <li>
                                    <span onClick={handleComocomprar}>¿Cómo comprar?</span>
                                </li>
                                <li>
                                    <span onClick={handelTerminos}>Términos y Condiciones</span>
                                </li>
                                <li>
                                    <span onClick={handlePoliticas}>Politicas de privacidad</span>
                                </li>
                            </ul>
                        </div>
                        <div className="contacto column is-2 footer-column-divisor">
                            <ul className="footer-list">
                                <li>
                                    <span onClick={handleDespacho}>Despacho</span>
                                </li>
                                <li>
                                    <span onClick={handlePreguntas}>Preguntas frecuentes</span>
                                </li>
                                <li>
                                    <span onClick={handleContacto}>Venta Empresas</span>
                                </li>
                                <li>
                                    <span onClick={handleContacto}>Contáctanos</span>
                                </li>
                            </ul>
                        </div>
                    <div className="svg-footer-container column is-2">
                        <a href="/"><LogoWhite className="svg-logo-white"/></a>
                        <div className="svg-footer-social">
                            <a href="https://www.facebook.com/plantbcl"  target="_blank" rel="noopener noreferrer"><FaceIcon /></a>
                            <a href="https://www.instagram.com/plantb_chile/"  target="_blank" rel="noopener noreferrer"><InstaIcon /></a>
                        </div>
                    </div>
                    <div className="svg-footer-container column is-2">
                        {/* <a href="/"><Webpay className="svg-logo-white"/></a> */}
                             <a href="/"><img alt="mercado pago" src={MercadoPago} className="svg-logo-white"/></a>
                    </div>
                    </div>
                    <div className="divider footer-green columns mobil-description">
                        <div className="contacto column is-12 footer-column-divisor">
                            <ul className="footer-list">
                                <li>
                                    <span onClick={handleQuees}>¿Qué es Plant B?</span>
                                </li>
                                <li>
                                    <span onClick={handleComocomprar}>¿Cómo comprar?</span>
                                </li>
                                <li>
                                    <span onClick={handelTerminos}>Términos y Condiciones</span>
                                </li>
                                <li>
                                    <span onClick={handlePoliticas}>Politicas de privacidad</span>
                                </li>
                            </ul>
                        </div>
                        <div className="contacto column is-12 footer-column-divisor">
                            <ul className="footer-list">
                                <li>
                                    <span onClick={handleDespacho}>Despacho</span>
                                </li>
                                <li>
                                    <span onClick={handlePreguntas}>Preguntas frecuentes</span>
                                </li>
                                <li>
                                    <span onClick={handleContacto}>Venta Empresas</span>
                                </li>
                                <li>
                                    <span onClick={handleContacto}>Contáctanos</span>
                                </li>
                            </ul>
                        </div>
                    <div className="svg-footer-container column is-12">
                        <a href="/"><LogoWhite className="svg-logo-white"/></a>
                        <div className="svg-footer-social">
                            <a href="https://www.facebook.com/plantbcl"  target="_blank" rel="noopener noreferrer"><FaceIcon /></a>
                            <a href="https://www.instagram.com/plantb_chile/"  target="_blank" rel="noopener noreferrer"><InstaIcon /></a>
                        </div>
                    </div>
                    <div className="svg-footer-container column is-12">
                    <div className="column is-6">
                             <a href="/"><img src={MercadoPago} alt="mercado pago 2" className="svg-logo-white"/></a>
                        </div>
                    </div>
                    </div>
        </footer>
    );
}
export default Footer;
