import React from "react"
import Base from "../layout/Base"

const Preguntas = () => {
    return (
        <Base>
              <div id='collection-component-shopify' style={{display: "none"}}></div>
            <div className="text-page-title"><p><b>Preguntas Frecuentes</b></p></div>
            <div class="text-page-content">
                <p><strong>Cambios y devoluciones</strong></p>
                <p><strong>En Plant B te ofrecemos dos tipos de devoluciones:</strong></p>
                <ol>
                <li style={{fontWeight : "400;"}}><span style={{fontWeight : "400;"}}>Por insatisfacci&oacute;n:&nbsp;En caso de que recibas el producto y no cumpla con tus expectativas, te ofrecemos la opci&oacute;n de devoluci&oacute;n en dinero o cr&eacute;ditos PlantB, siempre y cuando nos avises dentro de los primeros 10 d&iacute;as luego de recibida tu compra. Ser&aacute; tu responsabilidad hacernos llegar el producto.</span></li>
                <li style={{fontWeight : "400;"}}><span style={{fontWeight : "400;"}}>Por fallas del producto:&nbsp;Si tu producto presenta alguna falla de origen, dentro de los 3 primeros meses luego de recibido o retirado en nuestra oficina, ser&aacute; derivado al servicio t&eacute;cnico. Si al momento de la compra elegiste "Retiro en Oficina", tendr&aacute;s que llevarlo directamente a la direcci&oacute;n que te entregaremos. En cambio, si escogiste "Despacho", PlantB coordinar&aacute; el env&iacute;o al servicio t&eacute;cnico.</span></li>
                <li style={{fontWeight : "400;"}}><span style={{fontWeight : "400;"}}>Consideraciones generales: Para solicitar un cambio, el producto debe estar en perfectas condiciones, es decir, con todos los embalajes originales, etiquetas y manual de uso. Las devoluciones v&iacute;a transferencia bancaria se hacen efectivas en un tiempo m&aacute;ximo de 15 d&iacute;as h&aacute;biles.</span></li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>Compras</strong></p>
                <h5><span style={{fontWeight : "400;"}}>&iquest;Es seguro comprar en Plant B?</span></h5>
                <p><span style={{fontWeight : "400;"}}>Nuestro sitio cumple con los m&aacute;s altos est&aacute;ndares de seguridad, al comprar en Plant B todos tus datos est&aacute;n protegidos. Para que compres tranquilo, en nuestro sitio adoptamos las siguientes medidas de seguridad:</span></p>
                <ol>
                <li style={{fontWeight : "400;"}}><span style={{fontWeight : "400;"}}>Certificado de Seguridad SSL que nos permite asegurar la autenticidad del sitio, como el cifrado de toda la informaci&oacute;n que nos entregan nuestros clientes. Cada vez que el usuario se registra en Plant B y nos entrega informaci&oacute;n de car&aacute;cter personal, sin importar el lugar geogr&aacute;fico en donde se encuentre, a efectos de comprar un producto, el navegador por el cual ejecuta la compra se conecta al sitio a trav&eacute;s del protocolo SSL que acredita que nuestro cliente se encuentra efectivamente en el Plant B y en nuestros servidores (esto puedes verlo porque aparece el c&oacute;digo HTTPS en la barra de direcciones del navegador). De esta forma se establece un m&eacute;todo de cifrado de la informaci&oacute;n entregada por nuestros clientes y una clave de sesi&oacute;n &uacute;nica. Esta tecnolog&iacute;a permite que la informaci&oacute;n de car&aacute;cter personal de nuestros clientes, como sus nombres, direcciones y datos de tarjetas bancarias, sean codificadas antes, para que no pueda ser le&iacute;da cuando viaja a trav&eacute;s de internet. Todos los certificados SSL se crean para un servidor particular, en un dominio espec&iacute;fico y para una entidad comercial comprobada.</span></li>
                <li style={{fontWeight : "400;"}}><span style={{fontWeight : "400;"}}>Certificado de Site Blindado, quiere decir que el sitio est&aacute; permanentemente sujeto a evaluaciones de posibles vulnerabilidades (en tiempo real).</span></li>
                </ol>
                <h5><span style={{fontWeight : "400;"}}>&iquest;Qu&eacute; informaci&oacute;n pedimos en PlantB para comprar?</span></h5>
                <p><span style={{fontWeight : "400;"}}>Cuando realices una compra, s&oacute;lo te pediremos que ingreses la informaci&oacute;n necesaria para el pago y el env&iacute;o de tu compra. Esto incluye tus datos personales, direcci&oacute;n de env&iacute;o, correo electr&oacute;nico y los temas relacionados con el despacho. Es importante que sepas que nunca conservaremos la informaci&oacute;n de tu tarjeta de cr&eacute;dito.</span></p>
                <h5><span style={{fontWeight : "400;"}}>&iquest;Qu&eacute; hago si pagu&eacute; y no recib&iacute; el correo de confirmaci&oacute;n?</span></h5>
                <p><span style={{fontWeight : "400;"}}>Si pagaste y no recibiste el correo de confirmaci&oacute;n de tu compra, puedes escribirnos y te responderemos lo antes posible. Tambi&eacute;n puedes probar volviendo a actualizar tu correo o revisando tu carpeta de spam, para asegurarnos que nuestro correo no cay&oacute; ah&iacute;.</span></p>
                <h5><span style={{fontWeight : "400;"}}>&iquest;Tienen tienda f&iacute;sica?</span></h5>
                <p><span style={{fontWeight : "400;"}}>Plant B funciona como una tienda 100% online, donde puedes conocer todos nuestros productos y servicios a trav&eacute;s de nuestro sitio web. Trabajamos cada d&iacute;a para que tu experiencia de compra sea mil veces mejor que al comprar en una tienda f&iacute;sica.</span></p>
                <h5><span style={{fontWeight : "400;"}}>&iquest;Qu&eacute; formas de pago existen en Plant B?</span></h5>
                <p><span style={{fontWeight : "400;"}}>En Plant B tenemos disponibles los siguientes proveedor de servicios de pago: Webpay, Paypal , Kiphu o una transferencia bancaria directa. &iexcl;Estamos corriendo para darte un mejor servicio de compra!</span></p>
                <h5><span style={{fontWeight : "400;"}}>&iquest;Puedo comprar un producto para regalo?</span></h5>
                <p><span style={{fontWeight : "400;"}}>Todav&iacute;a no tenemos disponible esta opci&oacute;n, pero apenas lo tengamos nos encargaremos de avisarte&nbsp;</span></p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p><strong>Despachos</strong></p>
                <p>&nbsp;</p>
                <p><strong>&iquest;C&oacute;mo puedo saber el estado de mi pedido?</strong></p>
                <p><span style={{fontWeight : "400;"}}>Para conocer el estado de tu pedido, debes haber iniciado tu sesi&oacute;n en la secci&oacute;n Mi Cuenta y buscar tus compras en la secci&oacute;n &ldquo;Mis pedidos&rdquo;, donde estar&aacute; actualizada la informaci&oacute;n de tu env&iacute;o.</span></p>
                <p><strong>&iquest;Qu&eacute; hago si mi pedido no ha llegado?</strong></p>
                <p><span style={{fontWeight : "400;"}}>Si tu pedido no ha llegado dentro del plazo establecido, cont&aacute;ctanos a trav&eacute;s de nuestro sitio web o a trav&eacute;s de Facebook. Ojal&aacute; nunca tengas que leer esta pregunta, ya que trabajamos con el &lt;3 para que esto no ocurra p=""&gt;</span></p>
                <p><strong>&iquest;Plant B realiza env&iacute;os a todo Chile?</strong></p>
                <p><span style={{fontWeight : "400;"}}>S&iacute;, en Plant B queremos que todas las personas puedan adquirir nuestros productos y por ello despacharemos lo que necesites donde sea que te encuentres. Estamos desde Arica a Punta Arenas.</span></p>
                <p><strong>&iquest;Plant B hace env&iacute;os fuera de Chile?</strong></p>
                <p><span style={{fontWeight : "400;"}}>Por el momento s&oacute;lo despachamos dentro de Chile.</span></p>
                <p><strong>&iquest;Cu&aacute;les son los plazos de los despachos?</strong></p>
                <p><span style={{fontWeight : "400;"}}>Nuestros plazos de entrega comienzan a correr una vez que recibimos tu pedido, comprometi&eacute;ndonos a despacharlo dentro de los tiempos establecidos. Los tiempos de env&iacute;o dependen de la regi&oacute;n en la que vives:</span></p>
                <ol>
                <li style={{fontWeight : "400;"}}><span style={{fontWeight : "400;"}}>Si vives en la Regi&oacute;n Metropolitana, las entregas las realizamos de lunes a viernes entre 9:00 y 18:00 horas, dentro de 2 a 5 d&iacute;as h&aacute;biles.</span></li>
                <li style={{fontWeight : "400;"}}><span style={{fontWeight : "400;"}}>Si vives en regiones, las entregas se realizan de lunes a viernes entre 9:00 y 18:00 horas y el tiempo depender&aacute; del lugar donde est&eacute;s, pero no debiera tardar m&aacute;s de 7 d&iacute;as h&aacute;biles.</span></li>
                <li style={{fontWeight : "400;"}}><span style={{fontWeight : "400;"}}>Si vives en las siguientes comunas, el env&iacute;o tardar&aacute; entre 7 a 12 d&iacute;as h&aacute;biles.</span></li>
                </ol>
                <p><span style={{fontWeight : "400;"}}>*Durante las compras del CyberMonday (8, 9 y 10 de octubre de 2018), todos los env&iacute;os se llevar&aacute;n a cabo en un plazo de 7 a 10 d&iacute;as h&aacute;biles, salvo en regiones extremas donde se realizar&aacute;n en un plazo de 10 a 15 d&iacute;as h&aacute;biles.</span></p>
                <p><strong>&iquest;Que hago si no estaba al momento de la entrega?</strong></p>
                <p><span style={{fontWeight : "400;"}}>Si no estabas cuando llegamos a dejar el producto a tu casa u oficina, nos coordinaremos contigo para resolverlo.</span></p>
                <p><span style={{fontWeight : "400;"}}>En caso de ingresar una direcci&oacute;n de dif&iacute;cil acceso o localizaci&oacute;n (como podr&iacute;a ser "Camino Azul, km 3, casa roja, Caj&oacute;n del Maipo" u otras similares), se har&aacute; el intento de entrega en dicho domicilio. Sin embargo, si la agencia encargada del despacho no encuentra dicha direcci&oacute;n, el pedido volver&aacute; a PlantB, situaci&oacute;n en la cual solicitaremos al cliente una direcci&oacute;n de f&aacute;cil acceso para realizar un nuevo despacho que se cobrar&aacute; al cliente.</span></p>
                <p><strong>&iquest;Puedo enviar mi producto a otra direccion?</strong></p>
                <p><span style={{fontWeight : "400;"}}>S&iacute;, al momento de comprar debes elegir una direcci&oacute;n de despacho, que puede ser distinta a la direcci&oacute;n de tu casa.</span></p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p><strong>Garant&iacute;a</strong></p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight : "400;"}}>Todos los productos PlantB tienen una garant&iacute;a de 3 meses si el objeto se encuentra defectuoso, adem&aacute;s de ofrecerte reparaci&oacute;n en el servicio t&eacute;cnico correspondiente si tu compra presenta fallas de f&aacute;brica, pero no si el desperfecto se origina por una mala manipulaci&oacute;n una vez recibido el producto.</span></p>
                <p><span style={{fontWeight : "400;"}}>En el caso de los concursos, tienes un plazo m&aacute;ximo de 2 meses para retirar tu premio en nuestra oficina.</span></p>
                <p>&nbsp;</p>
            </div>
        </Base>
    )
}
export default Preguntas;
