import React from 'react'
import {Helmet} from 'react-helmet'

const Seo = () => {

    const title = 'Plant B'
    const description = 'descripcion plantb'
    const url = 'https://plantb.cl/'

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content="" />

            {/* Facebook metas*/}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="" />

            {/* Twitter metas */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content="" />
        </Helmet>
    )
}

export default Seo